// src/components/home.js

import React, { useState } from "react";
import { HiMenuAlt4, HiX } from "react-icons/hi";
import "./navbar.scss";
// import logoPi from "../../assets/images/HS logo.svg";
import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";

export default function Navbar() {
  const [toggle, setToggle] = useState(false);

  return (
    <nav className="app__navbar">
      <div className="website-name pt-2 text-[#b3cceb]">
        <NavLink to="/" className="text-white">
          <h2>RBM CREATE</h2>
        </NavLink>
      </div>
      <ul className="app__navbar-links">
        <li key="/">
          <NavLink
            to={`/`}
            exact="true"
            onClick={() => setToggle(false)}
            // activeClassName="text-red"
            className=" text-[#FFFFFF] hover:text-red-800 "
          >
            Home
          </NavLink>
        </li>
        {["post", "projects", "about", "contact"].map((item) => (
          <li key={item}>
            <NavLink
              to={`/${item}`}
              // exact
              onClick={() => setToggle(false)}
              // activeClassName="text-red"
              className=" text-[#FFFFFF]"
            >
              {item}
            </NavLink>
          </li>
        ))}
      </ul>

      <div className="app__navbar-menu">
        <HiMenuAlt4 onClick={() => setToggle(true)} />

        {toggle && (
          <motion.div
            whileInView={{ x: [100, 0] }}
            transition={{ duration: 0.45, ease: "easeOut" }}
          >
            <HiX className="x-icon" onClick={() => setToggle(false)} />

            <ul>
              <li key="/">
                <NavLink
                  to={`/`}
                  exact="true"
                  onClick={() => setToggle(false)}
                  // activeClassName="text-red"
                  className=" text-[#254E58] "
                >
                  HOME
                </NavLink>
              </li>
              {["post", "projects", "about", "contact"].map((item) => (
                <li key={item}>
                  <NavLink
                    to={`/${item}`}
                    // exact
                    onClick={() => setToggle(false)}
                    // activeClassName="text-red"
                    className=" text-[#254E58]"
                  >
                    {item}
                  </NavLink>
                </li>
              ))}
            </ul>
          </motion.div>
        )}
      </div>
    </nav>
  );
}
