import React, { useRef, useState } from "react";
import "./contact.scss";
import emailjs from "@emailjs/browser";

import { ImLocation } from "react-icons/im";
import { IoIosSend } from "react-icons/io";

import {
  AiOutlineGithub,
  AiFillInstagram,
  AiFillLinkedin,
  AiFillTwitterCircle,
  AiTwotoneMail,
  AiFillPhone,
} from "react-icons/ai";

const Contact = () => {
  const formRef = useRef();
  const [done, setDone] = useState(false);
  // const theme = useContext(ThemeContext);
  // const darkMode = theme.state.darkMode;

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_fbbufg3",
        "template_vkg3ni8",
        formRef.current,
        "user_PxHDwjJuTxlYr5C13CDOv"
      )
      .then(
        (result) => {
          console.log(result.text);
          setDone(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };
  return (
    <div id="contact" className="contact_page w-full h-screen">
      {/* <div className="c-bg"></div> */}
      <div className="c-wrapper">
        <div className="c-left">
          <h1 className="c-title page-title">Contact me!</h1>
          <div className="c-info">
            <div className="c-info-item">
              <AiFillPhone className="c-icon" />
              Not Active
            </div>
            <div className="c-info-item">
              <AiTwotoneMail className="c-icon" />
              contact@example.com
            </div>
            <div className="c-info-item">
              <ImLocation className="c-icon" />
              Houston Texas
            </div>
            <div className="socials-link">
              <a href="https://github.com/racine101" target="blank">
                <div className="social-icon rounded-full shadow-lg shadow-gray-400 p-6 cursor-pointer hover:scale-110 ease-in duration-300">
                  <AiOutlineGithub size={28} />
                </div>
              </a>
              <a
                href="https://www.instagram.com/techismypassion/"
                target="blank"
              >
                <div className="social-icon rounded-full shadow-lg shadow-gray-400 p-6 cursor-pointer hover:scale-110 ease-in duration-300">
                  <AiFillInstagram size={28} />
                </div>
              </a>
              <a
                href="https://www.linkedin.com/in/racine-mclean-14728513b"
                target="blank"
              >
                <div className="social-icon rounded-full shadow-lg shadow-gray-400 p-6 cursor-pointer hover:scale-110 ease-in duration-300">
                  <AiFillLinkedin size={28} />
                </div>
              </a>
              <a
                href="https://www.instagram.com/techismypassion/"
                target="blank"
              >
                <div className="social-icon rounded-full shadow-lg shadow-gray-400 p-6 cursor-pointer hover:scale-110 ease-in duration-300">
                  <AiFillTwitterCircle size={28} />
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="c-right">
          <p className="c-desc">
            <b>What’s your story?</b> Get in touch. Always available for
            freelancing if the right project comes along.
          </p>
          <form ref={formRef} onSubmit={handleSubmit}>
            <input
              style={{ backgroundColor: "#88bdbc", borderRadius: "10px" }}
              type="text"
              placeholder="Name"
              name="user_name"
            />
            <input
              style={{
                backgroundColor: "#88bdbc",
                borderRadius: "10px",
              }}
              type="text"
              placeholder="Subject"
              name="user_subject"
            />
            <input
              style={{ backgroundColor: "#88bdbc", borderRadius: "10px" }}
              type="text"
              placeholder="Email"
              name="user_email"
            />
            <textarea
              style={{
                backgroundColor: "#88bdbc",
                borderRadius: "10px",
              }}
              rows="5"
              placeholder="Message"
              name="message"
            />
            <button className="shadow-lg shadow-gray-400 hover:scale-110 ease-in duration-300">
              <h3>Submit</h3>
              <IoIosSend />
            </button>
            {done && "Thank you..."}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
