// src/components/home.js

import React from "react";
import Mypic from "../../assets/images/mac_in_dark.avif";
import {
  AiOutlineGithub,
  AiFillInstagram,
  AiFillLinkedin,
  AiFillTwitterCircle,
} from "react-icons/ai";
import "./home.scss";

export default function Home() {
  return (
    <main id="home" className="relative justify-center items-center">
      <img
        src={Mypic}
        alt="/"
        className="absolute object-cover w-full h-full"
      />
      <section className="title_section relative flex flex-col justify-center content-center items-center min-h-screen  pt-12 lg:pt-64 px-8">
        <h1 className="text-8xl font-[Oswald] text-white font-bold leading-none sm:p-12 lg:leading-snug">
          Let's Create
        </h1>
        <div className="socials-link-home ">
          <a href="https://github.com/racine101" target="blank">
            <div className="social-icon rounded-full shadow-lg shadow-gray-400 p-6 cursor-pointer hover:scale-110 ease-in duration-300">
              <AiOutlineGithub size={28} />
            </div>
          </a>
          <a href="https://www.instagram.com/techismypassion/" target="blank">
            <div className="social-icon rounded-full shadow-lg shadow-gray-400 p-6 cursor-pointer hover:scale-110 ease-in duration-300">
              <AiFillInstagram size={28} />
            </div>
          </a>
          <a
            href="https://www.linkedin.com/in/racine-mclean-14728513b"
            target="blank"
          >
            <div className="social-icon rounded-full shadow-lg shadow-gray-400 p-6 cursor-pointer hover:scale-110 ease-in duration-300">
              <AiFillLinkedin size={28} />
            </div>
          </a>
          <a href="https://www.instagram.com/techismypassion/" target="blank">
            <div className="social-icon rounded-full shadow-lg shadow-gray-400 p-6 cursor-pointer hover:scale-110 ease-in duration-300">
              <AiFillTwitterCircle size={28} />
            </div>
          </a>
        </div>
      </section>
    </main>
  );
}
