import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";

import "./App.scss";
import Navbar from "./components/Navbar/navbar";
import Home from "./components/Home/home";
import About from "./components/About/about";
import Contact from "./components/Contact/contact";
import Projects from "./components/Projects/projects";
import SinglePost from "./components/singlePost/singlePost";
import Footer from "./components/Footer/footer";
import Post from "./components/Post/post";

function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route element={<Home />} path="/" />
        <Route element={<SinglePost />} path="/post/:slug" />
        <Route element={<Post />} path="/post" />

        <Route element={<Projects />} path="/projects" />
        <Route element={<About />} path="/about" />
        <Route element={<Contact />} path="/contact" />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
