// src/components/home.js

import React, { useState, useEffect } from "react";
// import { IoMdArrowDropright } from "react-icons/io";
import sanityClient from "../../client";
import imageUrlBuilder from "@sanity/image-url";
import BlockContent from "@sanity/block-content-to-react";
// import { } from "react-icons/ai";
import "./about.scss";

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}

export default function About() {
  const [author, setAuthor] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "author"]{
      name,
      bio,
      "authorImage": image.asset->url
    }`
      )
      .then((data) => setAuthor(data[0]))
      .catch(console.error);
  }, []);

  if (!author) return <div>Loading...</div>;

  return (
    <main
      className="min-h-screen  relative  "
      style={{ backgroundColor: "#254E58" }}
    >
      <h1 className="page-title text-3xl text-center text-white pt-24 ">
        ABOUT ME
      </h1>
      <section
        className=" p-12 lg:w-1/2 lg:h-fit  lg:mx-auto relative items-center  justify-center  flex-col"
        style={{ backgroundColor: "#88BDBC" }}
      >
        <div className="flex flex-col justify-center items-center md:mb-12 lg:mb-12">
          <div className="relative w-64 h-64 bg-gradient-to-b from-[#254E58] rounded-full lg:w-80 lg:h-80 place-items-center overflow-hidden">
            <img
              src={urlFor(author.authorImage).url()}
              className=" rounded-full "
              alt={author.name}
            />
          </div>
          <div className="text-lg flex  lg:w-3/5  lg:pt-8 flex-col justify-center text-center ">
            <h1 className="text-2xl text-white mt-4 mb-4 Helvetica Arial sans-serif">
              Hey there. I'm{" "}
              <a
                href="https://www.linkedin.com/in/racine-mclean-14728513b/"
                target={"_blank"}
                rel="noreferrer"
              >
                <span className="text-[#112d32]">{author.name}</span>
              </a>
            </h1>
            <div className="prose lg:prose-xl text-white">
              <BlockContent
                blocks={author.bio}
                projectID="egxebgzp"
                dataset="production"
              />
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
