import React from "react";
import "./footer.scss";

export default function Footer() {
  return (
    <div className="footer">
      {/* <AiOutlineCopyrightCircle size={8} />{" "} */}
      <span className="copyright-text text-white p-1">
        © 2022-Present RBM TECH - ALL RIGHTS RESERVED.
      </span>
    </div>
  );
}
