// src/components/home.js

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import sanityClient from "../../client";
import imageUrlBuilder from "@sanity/image-url";
import BlockContent from "@sanity/block-content-to-react";
import "./singleProject.scss";

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}

export default function SinglePost() {
  const [singlePost, setSinglePost] = useState(null);
  const { slug } = useParams();

  useEffect(() => {
    sanityClient
      .fetch(
        `*[slug.current == "${slug}"]{
      title,
      _id,
      slug,
      mainImage{
        asset->{
          _id,
          url
        }
      },
      body,
      "name": author->name,
      "authorImage": author->image
    }`
      )
      .then((data) => setSinglePost(data[0]))
      .catch(console.error);
  }, [slug]);

  if (!singlePost) return <div>Loading...</div>;

  return (
    <main className="bg-[#254e58] min-h-screen p-12">
      <article className="container shadow-lg mx-auto bg-[#88bdbc] rounded-lg mt-24">
        <header className="relative">
          <div className="absolute h-full w-full flex items-center justify-center p-8">
            <div className="bg-[#88bdbc] bg-opacity-75 rounded p-12">
              <h1 className="text-3xl text-white font-[Oswald] lg:text-6xl mb-4">
                {singlePost.title}
              </h1>
              <div className="flex justify-center text-white">
                <img
                  src={urlFor(singlePost.authorImage).url()}
                  alt={singlePost.name}
                  className="w-8 h-8 rounded-full"
                />
                <h4 className="flex items-center text-white pl-2 text-1xl">
                  {singlePost.name}
                </h4>
              </div>
            </div>
          </div>
          <img
            src={singlePost.mainImage.asset.url}
            alt={singlePost.title}
            className="w-full object-cover rounded-t p-4"
            style={{ height: "400px" }}
          />
        </header>
        <div className="px-5 lg:px-48 py-12 lg:py-20 prose text-white lg:prose-xl max-w-full">
          <BlockContent
            blocks={singlePost.body}
            projectId="egxebgzp"
            dataset="production"
          />
        </div>
      </article>
    </main>
  );
}
