// src/components/home.js

import React, { useState, useEffect } from "react";
import "./post.scss";
import sanityClient from "../../client";
import { Link } from "react-router-dom";

export default function Post() {
  const [postData, setPost] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "post"]{
      title,
      slug,
      mainImage{
        asset->{
          _id,
          url
        },
        alt
      }
    }`
      )
      .then((data) => setPost(data))
      .catch(console.error);
  }, []);

  return (
    <main className="min-h-screen" style={{ backgroundColor: "#254E58" }}>
      <section className="container mx-auto py-24 px-6">
        <h1 className="page-title text-3xl text-white flex justify-center">
          BLOG POSTS
        </h1>
        <h2 className="text-lg text-white flex justify-center mb-12">
          Welcome to my Blog!
        </h2>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {postData &&
            postData.map((post, index) => (
              <article key={post.slug.current}>
                <Link to={"/post/" + post.slug.current}>
                  <span
                    className="block h-64 relative rounded shadow leading-snug bg-white border-l-8 border-[#88BDBC]"
                    key={index}
                  >
                    <img
                      src={post.mainImage.asset.url}
                      alt={post.mainImage.alt}
                      className="w-full h-full rounded-r object-cover absolute"
                    />
                    <span className="block relative h-full flex justify-end items-end pr-4 pb-4">
                      <h2 className="text-white-800 text-lg font-blod px-3 py-4 bg-[#88BDBC] text-red-100 bg-opacity-75 rounded">
                        {post.title}
                      </h2>
                    </span>
                  </span>
                </Link>
              </article>
            ))}
        </div>
      </section>
    </main>
  );
}
